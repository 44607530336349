import { clone } from "lodash";
import * as moment from "moment";
import { Moment } from "moment";
import * as qrious from "qrious";
import * as uuid from "uuid";

import { isLocalhost } from "@connect/Env";
import {  ANY_SORT_ARGS, API_SORT, NameUuid, SortTypes } from "@connect/Interfaces";
import { QriousConfig } from "Components/Devices/Constants";
import { sortBy, iteratee } from "lodash";

export const normalize = (thing: any) => typeof thing === "string" ? thing.toLowerCase() : thing;

const Utils = {
	// modified from https://stackoverflow.com/questions/10599933
	abbreviateNumber(num: number, fixed: number) {
		if (!num) {
			return num;
		}

		const numberAndPower = (num).toPrecision(2).split("e");
		const power = numberAndPower.length === 1 ? 0 : Math.floor(Math.min((numberAndPower as any)[1].slice(1), 14) / 3);
		const numberString = power < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, power * 3)).toFixed(fixed);
		const integer = num ? Number(numberString) : Math.abs(Number(numberString)); // enforce -0 is 0
		const abbreviatedNumber = integer + [ "", "K", "M", "B", "T" ][power];

		return abbreviatedNumber;
	},
	dayMinutes: 1440,
	isValidEmail(email: string) {
		// eslint-disable-next-line
		let regex = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/, "i");
		return regex.test(email);
	},
	isInstructionsPage() {
		return window.location.pathname.includes("devices") && window.location.pathname.includes("instructions");
	},
	isSamlPage() {
		return window.location.search.includes("saml");
	},
	isValidPassword(password: string) {
		// min 8 char, 1 alpha, 1 number
		// let passesRegex = new RegExp(/^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d).*$/).test(this.state.password);
		return password.length >= 8;
	},
	isValidMinChars(password: string, minChars: number) {
		return password.length >= minChars;
	},
	isValidMinLowerCase(password: string, minLowerCase: number) {
		return password.replace(/[^a-z]/g, "").length >= minLowerCase;
	},
	isValidMinUpperCase(password: string, minUpperCase: number) {
		return password.replace(/[^A-Z]/g, "").length >= minUpperCase;
	},
	isValidMinNumbers(password: string, minNumbers: number) {
		return password.replace(/[^0-9]/g, "").length >= minNumbers;
	},
	isValidMinSpecialChars(password: string, minSpecialChars: number) {
		return password.replace(/[0-9a-zA-Z]/g, "").length >= minSpecialChars;
	},
	getApiSort(filterSort: ANY_SORT_ARGS): API_SORT {
		const { sortType } = filterSort;
		switch (sortType) {
			case "0":
				return "name";
			case "1":
				return "-name";
			case "2":
				return "-created_at";
			case "3":
				return "created_at"
			default:
				return "created_at";
		}
	},
	getDaysBetweenDates(start: string, end: string) {
		let dates = [ start ];

		const currDate = moment(start);
		const lastDate = moment(end);

		while (currDate.add(1, "days").diff(lastDate) < 0) {
			const newDate = currDate.clone().format("YYYY-MM-DD");

			if (!dates.includes(newDate)) {
				dates.push(newDate);
			}
		}

		dates.push(end);
		return dates;
	},
	getGuid() {
		return uuid.v4();
	},
	getHumanReadableBytesize(bytesize: number = 0) {
		const kb = bytesize / 1024;

		if (kb >= 1024) {
			const mb = kb / 1024;

			if (mb >= 1024) {
				const gb = mb / 1024;

				return gb.toFixed(2) + "GB";
			} else {
				return mb.toFixed(1) + "MB";
			}
		} else {
			return Math.round(kb) + "KB";
		}
	},
	// modified from https://stackoverflow.com/questions/36098913
	getHumanReadableDuration(seconds: number, hideSeconds?: boolean, longFormat?: boolean, hidePlural?: boolean) {
		if (!seconds || seconds === 0) {
			let label = longFormat ? "0 second" : "0 sec";
			if (!hidePlural) {
				label += "s";
			}

			return label;
		}

		const dCount = Math.floor(seconds / (3600 * 24));
		const hCount = Math.floor(seconds % (3600 * 24) / 3600);
		const mCount = Math.floor(seconds % 3600 / 60);
		const sCount = Math.floor(seconds % 3600 % 60) + (Math.floor(seconds * 1000 % 1000) / 1000);

		const formatCount = (count: number, suffix: string, startSpace?: boolean, endSpace?: boolean, delimiter?: string) => {
			if (count > 0) {
				// eslint-disable-next-line
				return `${ startSpace ? " " : "" }${ count.toFixed(3) } ${ suffix }${ count > 1 && !hidePlural ? "s" : "" }${ delimiter }${ endSpace ? " " : "" }`;
			}

			return "";
		}

		const getDelimitString = (nextCount) => nextCount && longFormat ? "," : "";
		const dString = "day";
		const hString = "hour";
		const mString = longFormat ? "minute" : "min";
		const sString = longFormat ? "second" : "sec";

		const dDisplay = formatCount(dCount, dString, false, true, getDelimitString(hCount));
		const hDisplay = formatCount(hCount, hString, false, true, getDelimitString(mCount));
		const mDisplay = formatCount(mCount, mString, false, true, hideSeconds ? "" : getDelimitString(sCount));
		const sDisplay = formatCount(sCount, sString, false, false, "");

		return dDisplay + hDisplay + mDisplay + ( hideSeconds && seconds > 60 ? "" : sDisplay);
	},
	getHumanReadableDate(date: string) {
		const momentDate = moment(date);
		return momentDate.isValid() ? momentDate.local().format("MM/DD/YYYY h:mma") : "";
	},
	getHumanReadableShortDate(date: string) {
		let utc = moment.utc(date, moment.ISO_8601).toDate();
		let local = moment(utc)
			.local()
			.format("MM/DD/YY");

		if (moment(local, "MM/DD/YY").isValid()) {
			return local;
		}

		return "N/A";
	},
	getTruncatedName(name: string, maxCharacters: number = 11) {
		if (name && name.length > maxCharacters) {
			const charsMinusEllipsis = maxCharacters - 1;
			const charsOnLeft = Math.ceil(charsMinusEllipsis / 2);
			const charsOnRight = Math.floor(charsMinusEllipsis / 2);
			const leftPart = name.substr(0, charsOnLeft);
			const rightPart = name.substr(name.length - charsOnRight, charsOnRight);
			return leftPart + String.fromCharCode(0x2026) + rightPart;
		}
		return name;
	},
	getEndTruncatedName(name: string, maxCharacters: number = 11) {
		if (!name || name.length <= maxCharacters) {
			return name;
		}

		return name.substr(0, maxCharacters) + String.fromCharCode(0x2026);
	},
	properCase(inputString: string) {
		if (!inputString || !inputString.length) {
			return "";
		}

		return inputString.charAt(0).toUpperCase() + inputString.slice(1);
	},
	incrementByNameUuid(nameUuidKey: string, keyToIncrement: string) {
		// nameUuidKey corresponds to a NameUuid Partial in the array of objects we are reducing over
		// keyToIncrement corresponds to an integer which will be counted for each NameUuid
		return (accumulator, nextResult): { value: NameUuid, count: number }[] => {
			const value = nextResult[nameUuidKey];
			const count = nextResult[keyToIncrement];
			const exists = accumulator.findIndex((entry) =>
				(entry.value && entry.value.uuid) === (value && value.uuid));

			if (exists > -1) {
				accumulator[exists].count += count;
			} else {
				accumulator.push({ count, value });
			}

			return accumulator;
		};
	},
	isNullOrUndefined(value: any) {
		return value === null || value === undefined;
	},
	getComponentWidthFromPercent(percent: number = 0) {
		return Math.round(1080 * (percent / 100));
	},
	getComponentHeightFromPercent(percent: number = 0) {
		return Math.round(1920 * (percent / 100));
	},
	columnSorter(key: string) {
		const keys = key.split(".");
		return (a, b) => {
			let aVal = clone(a);
			let bVal = clone(b);

			keys.forEach((objectKey: string) => {
				// Dive deeper into the object if we can
				if (!this.isNullOrUndefined(aVal)) {
					aVal = aVal[objectKey];
				}
				if (!this.isNullOrUndefined(bVal)) {
					bVal = bVal[objectKey];
				}
			});

			// Check if either value is null or undefined
			if (this.isNullOrUndefined(aVal)) {
				return -1;
			}
			if (this.isNullOrUndefined(bVal)) {
				return 1;
			}

			return (normalize(aVal) > normalize(bVal)) ? -1 : 1;
		};
	},
	dateColumnSorter(key: string) {
		return (a, b) => {
			const aVal = a[key];
			const bVal = b[key];

			if (aVal === null) {
				return -1;
			}

			if (bVal === null) {
				return 1;
			}

			return (moment(aVal).isAfter(moment(bVal))) ? -1 : 1;
		};
	},
	sort(array: any[], by: string, ascending = false) {
		if (!array || !array.length) {
			return array || [];
		}

		const sortedArray = sortBy(array, iteratee(by));

		if (!ascending) {
			return sortedArray.reverse();
		}

		return sortedArray;
	},
	sortListBySortType(list: any[], sortType: SortTypes, forceSortBy?: string) {
		if (!list || !list.length || !sortType) {
			return list || [];
		}

		// legacy support for API V1 lists
		let createdSort = list.length && list[0].hasOwnProperty("createdAt") ? "createdAt" : "created_at";

		if (forceSortBy) {
			createdSort = forceSortBy;
		}

		switch (sortType) {
			case SortTypes.ALPHA:
				return this.sort(list, "name", true);
			case SortTypes.REVERSE_ALPHA:
				return this.sort(list, "name", false);
			case SortTypes.OLDEST_FIRST:
				return this.sort(list, createdSort, true);
			case SortTypes.NEWEST_FIRST:
			default:
				return this.sort(list, createdSort, false);
		}
	},
	hashCode(str: string) {
		if (!str || !str.length) {
			return "";
		}

		let hash = 0;

		for (let i = 0; i < str.length; i++) {
			let char = str.charCodeAt(i);
			hash += char;
		}

		return hash;
	},
	replaceSeparators(str: string, init: string, rep: string) {
		return str.split(init).join(rep);
	},
	getStartMinutesFromPx(itemYCoordinates: number, containerHeight: number) {
		return Math.round(this.dayMinutes / (containerHeight / itemYCoordinates));
	},
	getHeightFromDuration(duration: number, containerHeight: number) {
		return containerHeight / (this.dayMinutes / duration);
	},
	getDurationFromHeight(itemHeight: number, containerHeight: number) {
		return this.dayMinutes / (containerHeight / itemHeight);
	},
	getHoursMinutesFromStartTime(startMinutes: number) {
		return moment.utc(this.getMsFromMinutes(startMinutes)).format("hh:mm a");
	},
	getMsFromMinutes(minutes: number) {
		return minutes * 60000;
	},
	getSecondsFromDays(days: number) {
		return days * 24 * 60 * 60;
	},
	getAbsolutePosition(element: any) {
		// get the absolute position of an element, calculated by the offset of each parent
		let xPosition = 0;
		let yPosition = 0;

		while (element) {
			xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
			yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
			element = element.offsetParent;
		}

		return { x: xPosition, y: yPosition };
	},
	getISOTimestamp() {
		return moment.utc().format("YYYY-MM-DDTHH:mm:ss+00:00");
	},
	getDefaultTimestamp() {
		const d = new Date();
		const timestamp =
			(d.getMonth() + 1) +
			"." +
			d.getDate() +
			"." +
			d.getFullYear() +
			" " +
			d.getHours() +
			"." +
			d.getMinutes();
		return timestamp;
	},
	getDate(date: Moment) {
		return {
			month: date.month() + 1,
			year: date.year()
		}
	},
	getTimeStringDuration(duration: number, startTime: number) {
		const midnight = moment().startOf("day");
		const offsetDuration = moment.duration(startTime, "minutes");
		const lengthDuration = moment.duration(duration, "minutes");
		const start = midnight.clone().add(offsetDuration);
		const stop = start.clone().add(lengthDuration);

		return `${start.format("h:mm")} ~ ${stop.format("h:mm a")}`;
	},
	getMinutesFromSeconds(seconds: number) {
		return Math.round(seconds / 60);
	},
	validateName(value: string) {
		return !!value && /^(?![\s\b\t\.-])[\w\s\b\t\.-]+(?![\s\b\t\.-])$/.test(value);
	},
	getCsvFromObjectArray(arr: any[]) {
		const headers = Object.keys(arr[0]) + "\n";
		let data = "";

		arr.forEach((error) => {
			const values = Object.values(error);
			data = data.concat(values.toString() + "\n");
		});

		return headers.toString().concat(data.toString());
	},
	downloadText(text: string, name: string, type: string) {
		const file = new Blob([ text ], { type });
		const url = URL.createObjectURL(file);
		this.initiateDownload(url, name);
	},
	initiateDownload(href: string, fileName: string = "") {
		let a = document.createElement("a");
		a.href = href;
		a.download = fileName;
		a.target = "_blank";
		a.rel = "noopener noreferrer";
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	},
	downloadCsv(data: string, fileName: string = "") {
		let name = fileName;
		if (fileName.indexOf(".csv") === -1) {
			name += ".csv";
		}
		const blob = new Blob([ data ], {type: "text/csv;charset=utf-8;"});
		const url = URL.createObjectURL(blob);
		this.initiateDownload(url, name);
	},
	userHasPermissions(checkPermissions: string[], userPermissions: string[]) {
		return checkPermissions.every(permission => userPermissions.includes(permission));
	},
	formatTemperature(temp: number | string) {
		const value = typeof temp === "number"
			? temp
			: parseInt(temp, 10);

		if (typeof value === "number" && !isNaN(value)) {
			const c = value;
			const f = ((c * 1.8) + 32).toFixed(0);
			const cString = c + "℃";
			const fString = f + "℉";

			return `${fString} (${cString})`;
		}

		return "N/A";
	},
	copyToClipboard(text: string) {
		const container = document.createElement("textArea") as HTMLTextAreaElement;
		container.value = text;
		document.body.appendChild(container);
		container.select();
		document.execCommand("copy");
		document.body.removeChild(container);
	},
	printElement(divId: string) {
		const div = document.getElementById(divId);
		const content = div && div.innerHTML;
		const preview = window.open("", "Print", "height=600,width=800");

		if (preview) {
			preview.document.write("<html><head><title>Print</title>");
			preview.document.write("</head><body >");
			preview.document.write(content || "");
			preview.document.write("</body></html>");

			preview.document.close();
			preview.focus()
			preview.print();
			preview.close();
		}
	},
	properCaseString(str: string, separator: string) {
		return str
			.split(separator)
			.map((word) => Utils.properCase(word))
			.join(" ")
			.trim();
	},
	generateQrCode(hashId: string, integratorHashId?: string) {
		let qrConfig = new QriousConfig();
		let id = hashId;

		if (integratorHashId) {
			id += integratorHashId;
		}

		qrConfig.size = 2000;
		qrConfig.value = JSON.stringify({ id });

		const qr = new qrious(qrConfig);

		return qr.toDataURL();
	},
	padNumber(n: number) {
		const padded = n.toString();

		if (padded.length < 2) {
			return "0" + padded;
		}

		return padded;
	},
	getFormattedDuration(time: number) {
		const duration = moment.duration(time, "minutes");
		const minutes = this.padNumber(duration.minutes());

		return `${ duration.hours() }:${ minutes }`;
	},
	copyName(name: string) {
		if (name.length >= 183) {
			return "Copy of " + this.getTruncatedName(name, 181).replace("…", "...");
		}
		return "Copy of " + name;
	},
	removeSpecialChars(text: string) {
		// to conform with API naming conventions, strip out anything that
		// isn't a hyphen, letter, number, underscore, space, or period
		return text.replace(/[^a-zA-Z0-9\s\._-]/g, "");
	},
	throwIfNoQueryResults(error: Error) {
		if (error.message.includes("No query results")) {
			throw new Error(error.message);
		}
	},
	consoleDevError(error: Error) {
		if (isLocalhost()) {
			// eslint-disable-next-line
			console.error(error);
		}
	},
	// https://stackoverflow.com/a/5624139
	hexToRgb(color: string) {
		let hex = color;

		// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
		if (color.length === 4) {
			hex = "#" + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
		}

		let bigint = parseInt(hex, 16);

		return {
			r: (bigint >> 16) & 255,
			g: (bigint >> 8) & 255,
			b: bigint & 255
		};
	},
	rgbToHex(r: number, g: number, b: number) {
		const rHex = (r & 255) << 16;
		const gHex = (g & 255) << 8;
		const bHex = (b & 255);

		const rgbHex = rHex | gHex | bHex;

		return "#" + rgbHex.toString(16).padStart(6, "0");
	}
}

export { Utils }